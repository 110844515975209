<template>
  <div>
    <v-flex xs12>
      <v-card
        class="elevation-0"
        outlined
      >
        <HeaderCard>
          <v-icon
            class="mr-1"
            size="medium"
          >
            mdi-school-outline
          </v-icon>

          <b>{{ 'title.newLearnPage'.translate() }}</b>
        </HeaderCard>

        <v-card-text style="padding: 0;">
          <v-form
            ref="form"
            autocomplete="off"
          >
            <v-container
              grid-list-xl
              style="padding-top: 0; position: relative"
            >
              <v-card
                flat
                tile
                v-if="isLoading"
                class="d-flex justify-center pb-1 pt-3"
              >
                <v-progress-circular
                  :size="55"
                  indeterminate
                  color="primary"
                />
              </v-card>

              <v-layout
                v-else
                wrap
                style="padding: 16px 0 0"
              >
                <v-flex xs12>
                  <FieldText
                    label="Descrição"
                    v-model="pagina.descricao"
                  />
                </v-flex>

                <v-flex xs4>
                  <FieldStatus
                    label="Situação"
                    v-model="pagina.status"
                    v-if="showFieldStatus"
                  />
                </v-flex>

                <v-flex xs3>
                  <FieldPermissao
                    label="Permissão"
                    v-model="pagina.permissao"
                  />
                </v-flex>

                <v-flex
                  xs12
                  class="pb-0"
                >
                  <v-row no-gutters>
                    <v-col
                      :cols="12"
                      style="max-width: 320px"
                    >
                      <MobileView :content="conteudo" />
                    </v-col>

                    <v-col style="height: 570px">
                      <FieldPage v-model="obj" />
                    </v-col>
                  </v-row>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider class="my-3" />

        <v-card-actions style="padding-top: 0; padding-bottom: 0.7em;">
          <v-layout
            wrap
            justify-space-around
          >
            <v-btn
              color="blue darken-1"
              text
              @click="goBack"
            >
              Cancelar
            </v-btn>

            <v-btn
              v-if="!isLoading"
              color="success"
              small
              @click="handleSave"
              depressed
              :disabled="isDisable"
            >
              Salvar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
import FieldText from '@/components/fields/FieldText'
import FieldStatus from '@/components/fields/FieldStatus'
import FieldPage from '@/components/fields/FieldPage'
import HeaderCard from '@/components/HeaderCard'
import MobileView from '@/components/MobileView'
import FieldPermissao from '@/components/fields/FieldPermissao'
import { mapState } from 'vuex'
import { getSession } from '@/utils'
import { ROUTE_LEARN_PAGES_NEW, STATUS_SAVED, STATUS_ERROR } from '@/constants'

export default {
  components: {
    FieldPage,
    FieldPermissao,
    FieldStatus,
    FieldText,
    HeaderCard,
    MobileView
  },

  data () {
    const drawer = true
    const showFieldStatus = false
    const conteudo = ''
    const obj = { items: [], html: '' }

    return {
      drawer,
      obj,
      pagina: { conteudo },
      isLoading: true,
      showFieldStatus
    }
  },

  mounted () {
    const vm = this
    vm.isLoading = vm.$route.name !== ROUTE_LEARN_PAGES_NEW
    if (vm.isLoading) vm.getLearnPage()

    vm.$nextTick().then(() => {
      getSession().then((session) => {
        vm.showFieldStatus = Boolean(session.administrador || session.learnPageProfile)
      })
    })
  },

  computed: {
    ...mapState({
      learnPage: ({ learnPages }) => learnPages.row,
      status: ({ learnPages }) => learnPages.status
    }),

    conteudo () {
      return String(this.obj?.html || '')
    },

    isDisable () {
      return !String(this.pagina.descricao || '').trim() || !String(this.pagina.permissao || '').trim()
    }
  },

  methods: {
    getLearnPage () {
      const vm = this

      vm.$nextTick(() => {
        vm.$store.dispatch('loadLearnPage', vm.$route.params)
      })
    },

    goBack () {
      this.$router.go(-1)
    },

    handleSave () {
      const vm = this
      vm.isLoading = true
      const payload = Object.assign({}, vm.pagina)
      if (vm.$route.params?.id) payload.id = vm.$route.params.id
      vm.$store.dispatch('saveLearnPage', payload)
    }
  },

  watch: {
    obj: {
      deep: true,
      handler (obj) {
        const vm = this
        vm.pagina = Object.assign({}, vm.pagina, { conteudo: JSON.stringify(obj) })
      }
    },

    learnPage: {
      deep: true,
      handler (learnPage) {
        if (!learnPage?.id) return
        const vm = this
        const { conteudo, ...dataPage } = learnPage
        const pagina = Object.assign({}, vm.pagina, dataPage)
        const obj = parseContent(conteudo)
        const isLoading = false
        Object.assign(vm, { pagina, obj, isLoading })
      }
    },

    status: {
      deep: true,
      handler (status) {
        const vm = this

        if (status === STATUS_ERROR) {
          vm.isLoading = false
        }

        if (status === STATUS_SAVED) {
          vm.goBack()
          vm.$notify({
            type: 'success',
            title: 'Sucesso!',
            text: 'Pesquisa cadastrada.',
            duration: 7000
          })
        }
      }
    }
  }
}

const parseContent = (val) => {
  let obj = {}

  if (val) {
    try {
      obj = JSON.parse(val) || {}
    } catch {
      obj = {}
    }
  }

  if (!obj.html) obj.html = ''
  if (!Array.isArray(obj.items)) obj.items = []

  if (!obj.items.length && val) {
    let div = document.createElement('div')
    div.innerHTML = val

    while (true) {
      if (!div?.children?.length) {
        break
      }

      if (div.children.length === 1) {
        div = div.children[0]
        continue
      }

      if (div.children.length > 1) {
        div.innerHTML = div.innerHTML
          .replaceAll('<div>&nbsp;</div>', '')
          .replaceAll('<p class="ace-copy-paste-skip-this-tag">&nbsp;</p>', '')
        let c = 0

        for (const e of div.children) {
          obj.items.push({ id: `p${Date.now()}-${++c}`, data: { text: e.innerHTML }, component: 'PageText' })
        }

        obj.html = div.outerHTML

        break
      }
    }
  }

  return obj
}

</script>
