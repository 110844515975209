<template>
  <draggable
    ghost-class="ghost"
    v-model="data.items"
    :class="rowJustify"
    handle=".item-handler-row"
    :group="{ name: 'rows' }"
    :animation="250"
  >
    <template v-for="item in data.items">
      <PageItem
        :key="item.id"
        :item-id="item.id"
        item-type="PageColumns"
        v-model="item.data"
        @add="onAdd(item)"
        @remove="onRemove(item)"
      />
    </template>
  </draggable>
</template>

<script>
import PageItem from './PageItem'

export default {
  name: 'PageRows',
  components: { PageItem },

  props: {
    itemId: {},
    value: {}
  },

  data () {
    const data = clone(this.value)
    if (!Array.isArray(data.items)) data.items = []

    return { data }
  },

  computed: {
    rowJustify () {
      const vm = this
      return `fpe-row ${vm.data.justify || 'fpe-justify-around'}`.trim()
    }
  },

  methods: {
    onAdd (item) {
      const vm = this

      const data = clone(vm.data)
      if (!Array.isArray(data.items)) data.items = []

      const index = data.items.findIndex((i) => i.id === item.id)
      const now = Date.now()

      data.items.splice(index + 1, 0, {
        id: `col-${item}-${now}`,

        data: {
          justify: 'fpe-justify-around',

          items: [{
            id: `txt-${item}-${now}`,
            data: { text: `<p>Exemplo de Texto ${now}</p>` },
            component: 'PageText'
          }]
        }
      })

      vm.data = data
    },

    onRemove (item) {
      const vm = this
      const data = clone(vm.data)
      if (!Array.isArray(data.items)) data.items = []

      data.items = data.items.filter((i) => i.id !== item.id)
      vm.data = data

      if (!data.items.length) vm.$nextTick().then(() => vm.$emit('remove'))
    }
  },

  watch: {
    data: {
      deep: true,
      handler (item) {
        const vm = this

        const data = clone(item)
        if (!Array.isArray(data.items)) data.items = []

        if (isEqual(data, vm.value)) return
        vm.$emit('input', data)
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const data = clone(value)
        if (!Array.isArray(data.items)) data.items = []

        if (isEqual(data, vm.data)) return
        vm.data = data
      }
    }
  }
}

const clone = (source) => source ? JSON.parse(JSON.stringify(source)) : {}
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>
