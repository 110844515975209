<template>
  <div
    :id="itemId"
    :class="rootClass"
    :data-item-id="itemId"
    :data-item-type="itemType"
  >
    <component
      :is="itemType"
      :item-id="itemId"
      v-model="data"
      @remove="onRemove"
    />

    <PageHandler
      :horizontal="isHorizontal"
      :show="showHandler"
      :handler-class="handlerClass"
      @add="onAdd"
      @remove="onRemove"
      @config="onOpenConfig"
    />
  </div>
</template>

<script>
import PageHandler from '@/components/page/PageHandler'
import PageBus from '@/components/page/PageBus'

export default {
  name: 'PageItem',

  components: {
    PageHandler
  },

  props: {
    value: {},
    itemId: {},
    itemType: {}
  },

  data () {
    const data = clone(this.value)
    const showHandler = false
    return { data, showHandler }
  },

  mounted () {
    const vm = this
    PageBus.$on('handler-config', vm.showOptions)
    window.addEventListener('message', vm.onPopupMessage, true)
    vm.windowConfig = null
    vm.interval = null

    let openConfig = false
    if (!openConfig) openConfig = vm.itemType === 'PageImage' && !vm?.data?.img
    if (!openConfig) openConfig = vm.itemType === 'PageAudio' && !vm?.data?.audioId
    if (openConfig) vm.onOpenConfig()
  },

  beforeDestroy () {
    const vm = this
    PageBus.$off('handler-config', vm.showOptions)
    window.removeEventListener('message', vm.onPopupMessage, true)
    if (vm.interval) clearInterval(vm.interval)
    const canCloseConfig = vm.windowConfig && !vm.windowConfig.closed
    if (canCloseConfig) vm.windowConfig.close()
    vm.windowConfig = null
  },

  computed: {
    rootClass () {
      const vm = this
      const fpeCol = vm.data.col || 'fpe-col-auto'
      return `${fpeCol} fpe-open-config fpe-half-padding`.trim()
    },

    handlerClass () {
      const vm = this
      return vm.itemType === 'PageColumns' ? 'item-handler-row' : 'item-handler-col'
    },

    isHorizontal () {
      const vm = this
      return vm.itemType === 'PageColumns'
    }
  },

  methods: {
    onAdd () {
      this.$emit('add')
    },

    onRemove () {
      this.$emit('remove')
    },

    onPopupMessage (event) {
      const vm = this
      if (event.origin !== window.location.origin) return
      const config = parseJson(event.data)
      if (!config) return
      if (config.configId !== vm.itemId) return

      if (config.action === 'isOpened') return event.source.postMessage(JSON.stringify({ action: 'update', item: { id: vm.itemId, component: vm.itemType, data: vm.data } }), event.origin)
      if (config.action === 'update') return vm.updateData(config.item.data)
    },

    updateData (data) {
      const vm = this
      if (isEqual(data, vm.data)) return
      vm.data = clone(data)
    },

    showOptions (config) {
      const vm = this
      const showHandler = String(vm.itemId) === String(config.itemId) && vm.itemType === config.itemType
      if (showHandler === vm.showHandler) return
      vm.showHandler = showHandler
    },

    onOpenConfig () {
      const vm = this
      const hasWindowConfig = vm.windowConfig && !vm.windowConfig.closed

      if (hasWindowConfig) {
        vm.windowConfig.focus()
        return vm.windowConfig.postMessage(JSON.stringify({ action: 'update', item: { id: vm.itemId, component: vm.itemType, data: vm.data } }), window.location.origin)
      }

      let height = 425
      if (vm.itemType === 'PageText') height = 510
      if (vm.itemType === 'PageExpand') height = 510
      if (vm.itemType === 'PageImage') height = 600
      if (vm.itemType === 'PageRows') height = 210
      if (vm.itemType === 'PageColumns') height = 350
      vm.windowConfig = window.open(`/configuracao/${vm.itemId}/${vm.itemType}`, `Configuração ${vm.itemId}`, `width=800,height=${height}`)
    }
  },

  watch: {
    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(data, vm.value)) return
        const item = { id: vm.itemId, component: vm.itemType, data }
        if (vm.windowConfig) vm.windowConfig.postMessage(JSON.stringify({ action: 'update', item }), window.location.origin)
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        if (isEqual(value, vm.data)) return
        vm.data = clone(value)
      }
    }
  }
}

const clone = (source) => source ? JSON.parse(JSON.stringify(source)) : {}
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

const parseJson = (str) => {
  if (!str) return null

  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

</script>
