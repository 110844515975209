<template>
  <v-autocomplete
    :items="permissoes"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :clearable="clearable"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    :hide-details="hideDetails"
    v-model="status"
  />
</template>

<script>
import { getSession } from '@/utils'

export default {
  props: {
    icon: {},
    value: {},
    label: { default: 'field.status' },
    outlined: { default: false },
    readonly: { default: false },
    clearable: { default: false },
    autofocus: {},
    hideDetails: { default: true }
  },

  data () {
    const status = String(this.value || '').trim()

    const permissoes = [
      { value: 'CREATION', text: 'Criação' },
      { value: 'ARTWORK', text: 'Em preparação' },
      { value: 'REVIEW', text: 'Em revisão' }
    ]

    return {
      status,
      permissoes
    }
  },

  mounted () {
    const vm = this
    vm.$nextTick().then(() => {
      getSession().then((session) => {
        const canApprove = session.administrador || session.learnPageProfile === 'reviewer'
        if (canApprove) vm.permissoes.push({ value: 'APPROVED', text: 'Aprovado' })
      })
    })
  },

  watch: {
    status (status) {
      const newValue = String(status || '').trim()
      const oldValue = String(this.value || '').trim()
      if (oldValue === newValue) return
      this.$emit('input', newValue)
    },

    value (status) {
      const oldValue = String(this.status || '').trim()
      const newValue = String(status || '').trim()
      if (oldValue === newValue) return
      this.status = newValue
    }
  }
}
</script>
