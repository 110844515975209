<template>
  <draggable
    ghost-class="ghost"
    v-model="data.items"
    :class="colJustify"
    handle=".item-handler-col"
    :group="{ name: 'cols' }"
    :animation="250"
  >
    <template v-for="item in data.items">
      <PageItem
        :key="item.id"
        :item-id="item.id"
        :item-type="item.component"
        v-model="item.data"
        @remove="onRemove(item)"
      />
    </template>
  </draggable>
</template>

<script>
import PageItem from './PageItem'

export default {
  name: 'PageColumns',
  components: { PageItem },

  props: {
    value: {}
  },

  data () {
    const data = this.value ? clone(this.value) : {}
    return { data }
  },

  methods: {
    onRemove (item) {
      const vm = this
      const data = clone(vm.data)
      data.items = data.items.filter((i) => i.id !== item.id)
      vm.data = data
      if (!data.items.length) vm.$nextTick().then(() => vm.$emit('remove'))
    }
  },

  computed: {
    colJustify () {
      const vm = this
      return `fpe-col fpe-column full-height ${vm.data.justify || 'fpe-justify-around'}`.trim()
    }
  },

  watch: {
    data: {
      deep: true,
      handler (item) {
        const vm = this
        const data = item ? clone(item) : {}
        if (isEqual(data, vm.value)) return
        vm.$emit('input', data)
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const data = value ? clone(value) : {}
        if (isEqual(data, vm.data)) return
        vm.data = data
      }
    }
  }
}

const clone = (source) => source ? JSON.parse(JSON.stringify(source)) : {}
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>
