<template>
  <div
    class="fpe-collapse"
    style="expandStyle"
  >
    <input
      type="checkbox"
      :id="`collapse-toggle-${itemId}`"
    >
    <label
      :for="`collapse-toggle-${itemId}`"
      class="collapse-bar"
    >
      <ContentEditable v-model="data.title" />
      <span class="arrow" />
    </label>

    <div class="collapse-content">
      <draggable
        ghost-class="ghost"
        v-model="data.items"
        :class="colJustify"
        handle=".item-handler-col"
        :group="{ name: 'cols' }"
        :animation="250"
      >
        <template v-for="item in data.items">
          <PageItem
            :key="item.id"
            :item-id="item.id"
            :item-type="item.component"
            v-model="item.data"
            @remove="onRemove(item)"
          />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import PageItem from './PageItem'
import ContentEditable from './ContentEditable'

export default {
  name: 'PageExpand',

  components: {
    PageItem,
    ContentEditable
  },

  props: {
    itemId: {},
    value: {}
  },

  data () {
    const data = this.value ? clone(this.value) : {}
    return { data }
  },

  methods: {
    onRemove (item) {
      const vm = this
      const data = clone(vm.data)
      data.items = data.items.filter((i) => i.id !== item.id)
      vm.data = data
      if (!data.items.length) vm.$nextTick().then(() => vm.$emit('remove'))
    }
  },

  computed: {
    colJustify () {
      const vm = this
      return `fpe-col fpe-column full-height ${vm.data.justify || 'fpe-justify-around'}`.trim()
    },

    expandStyle () {
      const data = this.value ? Object.assign({}, this.value) : {}

      let style = ''

      if (data.paddingTop > 0) style += `margin-top: ${data.paddingTop}rem;`
      if (data.paddingLeft > 0) style += `margin-left: ${data.paddingLeft}rem;`
      if (data.paddingRight > 0) style += `margin-right: ${data.paddingRight}rem;`
      if (data.paddingBottom > 0) style += `margin-bottom: ${data.paddingBottom}rem;`

      return style
    }
  },

  watch: {
    data: {
      deep: true,
      handler (item) {
        const vm = this
        const data = item ? clone(item) : {}
        if (isEqual(data, vm.value)) return
        vm.$emit('input', data)
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const data = value ? clone(value) : {}
        if (isEqual(data, vm.data)) return
        vm.data = data
      }
    }
  }
}

const clone = (source) => source ? JSON.parse(JSON.stringify(source)) : {}
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

</script>
