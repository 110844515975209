<template>
  <div
    class="audio-link"
    :data-item-id="itemId"
    data-item-type="PageAudio"
    :data-audio-id="audioId"
    :style="audioStyle"
  >
    <a
      class="play-icon"
      :href="audioUrl"
    >▶️</a>

    <div class="audio-info">
      <a
        v-if="hasTitle"
        class="audio-name"
        :href="audioUrl"
      >{{ title }}</a>

      <a
        v-if="hasCaption"
        class="audio-time-author"
        :href="audioUrl"
      >{{ caption }}</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageAudio',

  props: {
    itemId: {},
    value: {}
  },

  computed: {
    audioStyle () {
      const data = this.value ? Object.assign({}, this.value) : {}

      let style = ''

      if (data.paddingTop > 0) style += `margin-top: ${data.paddingTop}rem;`
      if (data.paddingLeft > 0) style += `margin-left: ${data.paddingLeft}rem;`
      if (data.paddingRight > 0) style += `margin-right: ${data.paddingRight}rem;`
      if (data.paddingBottom > 0) style += `margin-bottom: ${data.paddingBottom}rem;`

      return style
    },

    hasCaption () {
      return !!this.caption
    },

    hasTitle () {
      return !!this.title
    },

    title () {
      return toStr(this.value?.title)
    },

    caption () {
      return toStr(this.value?.caption)
    },

    audioUrl () {
      return toStr(this.value?.url) || '#'
    },

    audioId () {
      return '300'
    }
  }
}

const toStr = (val) => String(val || '').trim()

</script>
