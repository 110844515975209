<template>
  <ContentEditable
    :data-item-id="itemId"
    data-item-type="PageText"
    v-model="data.text"
    :div-style="textStyle"
  />
</template>

<script>
import ContentEditable from './ContentEditable'

export default {
  name: 'PageText',

  components: {
    ContentEditable
  },

  props: {
    itemId: {},
    value: {}
  },

  data () {
    const data = this.value ? Object.assign({}, this.value) : {}
    data.text = toStr(data.text)
    return { data }
  },

  computed: {
    textStyle () {
      const data = this.value ? Object.assign({}, this.value) : {}

      let style = ''

      if (data.paddingTop > 0) style += `padding-top: ${data.paddingTop}rem;`
      if (data.paddingLeft > 0) style += `padding-left: ${data.paddingLeft}rem;`
      if (data.paddingRight > 0) style += `padding-right: ${data.paddingRight}rem;`
      if (data.paddingBottom > 0) style += `padding-bottom: ${data.paddingBottom}rem;`

      return style
    }
  },

  watch: {
    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(vm.value, data)) return
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const data = value ? Object.assign({}, value) : {}
        if (isEqual(vm.data, data)) return
        vm.data = clone(data)
      }
    }
  }
}

const clone = (source) => JSON.parse(JSON.stringify(source))
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

const toStr = (val) => {
  let desc = String(val || '').trim()
  if (desc === '<p></p>') desc = ''
  return desc
}

</script>
