<template>
  <div
    v-html="text"
    ref="textContent"
    contenteditable="true"
    :class="rootClass"
    :style="contentStyle"
  />
</template>

<script>
export default {
  name: 'ContentEditable',

  props: {
    value: {},
    divStyle: {},
    rootClass: {}
  },

  data () {
    const text = toStr(this.value)
    return { text }
  },

  computed: {
    contentStyle () {
      let style = 'margin: auto 0; width: 100%;'
      if (this.divStyle) style += this.divStyle
      return style
    }
  },

  mounted () {
    const vm = this
    vm.$nextTick().then(() => {
      vm.$refs.textContent.addEventListener('input', function () {
        this.querySelectorAll('span').forEach((span) => {
          const text = document.createTextNode(span.textContent)
          span.parentNode.replaceChild(text, span)
        })

        const newText = toStr(this.innerHTML)
        const oldText = toStr(vm.value)
        if (newText === oldText) return
        vm.$emit('input', newText)
      })
    })
  },

  beforeDestroy () {
    const vm = this
    if (vm.interval) clearInterval(vm.interval)
  },

  watch: {
    value: {
      deep: true,
      handler (value) {
        const vm = this
        const oldText = toStr(vm.$refs.textContent.innerHTML)
        const newText = toStr(value)
        if (newText === oldText) return
        vm.text = newText
      }
    }
  }
}

const toStr = (val) => {
  let desc = String(val || '').trim()
  if (desc === '<p></p>') desc = ''
  return desc
}

</script>
