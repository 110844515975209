<template>
  <v-card class="elevation-0">
    <v-card-text style="padding-left: 0; padding-right: 0;">
      <v-container
        grid-list-xl
        style="padding-top: 0"
      >
        <v-card
          flat
          tile
          v-if="isLoading"
          class="d-flex justify-center pb-1 pt-3"
        >
          <v-progress-circular
            :size="55"
            indeterminate
            color="primary"
          />
        </v-card>

        <div
          v-else
          class="fpe-root"
          v-html="conteudo"
        />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import '@/components/fields/FieldEditor'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      isLoading: true,
      tabs: null,
      conteudo: ''
    }
  },

  mounted () {
    const { params } = this.$route
    this.$store.dispatch('loadLearnPage', params)
  },

  computed: {
    ...mapState({
      row: ({ learnPages }) => learnPages.row
    })
  },

  watch: {
    row: {
      deep: true,
      handler (row) {
        if (!row?.id) return

        let html = ''

        try {
          html = JSON.parse(row.conteudo)?.html || ''
        } catch {
          html = row.conteudo
        }

        Object.assign(this, {
          isLoading: false,
          conteudo: html
        })
      }
    }
  }
}

</script>
