<template>
  <draggable
    ghost-class="ghost"
    :list="items"
    :sort="false"
    class="fpe-row fpe-justify-around"
    :group="{ name: 'cols', put: false, pull: 'clone' }"
    :clone="onClone"
  >
    <div
      class="fpe-col-auto"
      v-for="item in items"
      :key="item.label"
    >
      <v-btn
        small
        dark
        depressed
        color="primary"
      >
        <v-icon small>
          {{ item.icon }}
        </v-icon>
        <span class="ml-1">{{ item.label }}</span>
      </v-btn>
    </div>
  </draggable>
</template>

<script>

export default {
  name: 'PageComponents',

  data () {
    const items = loadItems()
    return { items }
  },

  methods: {
    onClone: (original) => prepareClone(original)
  }
}

const loadItems = () => {
  return [
    { label: 'Texto', icon: 'mdi-format-text', component: 'PageText' },
    { label: 'Imagem', icon: 'mdi-image-text', component: 'PageImage' },
    { label: 'Áudio', icon: 'mdi-music-circle-outline', component: 'PageAudio' },
    { label: 'Colunas', icon: 'mdi-view-column-outline', component: 'PageRows' },
    { label: 'Expansão', icon: 'mdi-arrow-expand-vertical', component: 'PageExpand' }
  ]
}

const prepareClone = (original) => {
  const model = Object.assign({}, original)
  model.id = `comp-0-${Date.now()}`

  model.data = {}

  if (model.component === 'PageRows') {
    model.data.items = [newColText(1), newColText(2)]
    model.data.justify = 'fpe-justify-around'
  }

  if (model.component === 'PageExpand') {
    model.data.title = '<h2>Título do componente de Expansão</h2>'
    model.data.items = [{
      id: `txt-1-${Date.now()}`,
      component: 'PageText',
      data: {
        text: '<p>Exemplo de Texto 1</p>',
        paddingTop: 0.5,
        paddingLeft: 0.5,
        paddingRight: 0.5,
        paddingBottom: 0.5
      }
    }, {
      id: `txt-2-${Date.now()}`,
      component: 'PageText',
      data: {
        text: '<p>Exemplo de Texto 2</p>',
        paddingTop: 0.5,
        paddingLeft: 0.5,
        paddingRight: 0.5,
        paddingBottom: 0.5
      }
    }]
  }

  if (model.component === 'PageText') {
    model.data.text = `<p>Exemplo de Texto: ${Date.now()}</p>`
    model.data.paddingTop = 0.5
    model.data.paddingLeft = 0.5
    model.data.paddingRight = 0.5
    model.data.paddingBottom = 0.5
  }

  if (model.component === 'PageAudio') {
    model.data.paddingTop = 0.5
    model.data.paddingLeft = 0.5
    model.data.paddingRight = 0.5
    model.data.paddingBottom = 0.5
  }

  if (model.component === 'PageExpand') {
    model.data.paddingTop = 0.5
    model.data.paddingLeft = 0.5
    model.data.paddingRight = 0.5
    model.data.paddingBottom = 0.5
  }

  if (model.component === 'PageImage') {
    model.data.img = null
    model.data.caption = '<p>Título da Imagem</p>'
    model.data.paddingTop = 0.5
    model.data.paddingLeft = 0.5
    model.data.paddingRight = 0.5
    model.data.paddingBottom = 0.5
  }

  delete model.icon
  delete model.label

  return model
}

const newColText = (item) => ({
  id: `col-${item}-${Date.now()}`,

  data: {
    col: 'fpe-col-auto',
    justify: 'fpe-justify-around',

    items: [{
      id: `txt-${item}-${Date.now()}`,
      component: 'PageText',
      data: {
        text: `<p>Exemplo de Texto ${item}</p>`,
        paddingTop: 0.5,
        paddingLeft: 0.5,
        paddingRight: 0.5,
        paddingBottom: 0.5
      }
    }]
  }
})

</script>
