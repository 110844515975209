<template>
  <div
    class="image-container fpe-column"
    :data-item-id="itemId"
    data-item-type="PageImage"
    :style="containerStyle"
  >
    <img
      :src="data.img"
      :style="imgStyle"
      class="image fpe-col-auto"
    >

    <ContentEditable
      v-if="data.caption"
      v-model="data.caption"
      root-class="fpe-col-auto"
      :div-style="captionStyle"
    />
  </div>
</template>

<script>
import ContentEditable from './ContentEditable'

export default {
  name: 'PageImage',

  components: {
    ContentEditable
  },

  props: {
    itemId: {},
    value: {}
  },

  data () {
    const data = this.value ? Object.assign({}, this.value) : {}
    data.caption = toStr(data.caption)
    return { data }
  },

  computed: {
    img () {
      const vm = this
      return toStr(vm.data.img)
    },

    containerStyle () {
      const data = this.value ? Object.assign({}, this.value) : {}

      let style = ''

      if (data.paddingTop > 0) style += `padding-top: ${data.paddingTop}rem;`
      if (data.paddingLeft > 0) style += `padding-left: ${data.paddingLeft}rem;`
      if (data.paddingRight > 0) style += `padding-right: ${data.paddingRight}rem;`
      if (data.paddingBottom > 0) style += `padding-bottom: ${data.paddingBottom}rem;`

      return style
    },

    imgStyle () {
      const vm = this
      let style = 'margin: 0 auto; width: 100%;'
      if (hasConfig(vm.data.maxWidth)) style += `max-width: ${vm.data.maxWidth};`
      if (hasConfig(vm.data.maxHeight)) style += `max-height: ${vm.data.maxHeight};`
      return style
    },

    captionStyle () {
      const vm = this
      let style = 'margin: 0 auto; width: 100%;'
      if (hasConfig(vm.data.maxWidth)) style += `max-width: ${vm.data.maxWidth};`
      return style
    }
  },

  watch: {
    data: {
      deep: true,
      handler (data) {
        const vm = this
        if (isEqual(vm.value, data)) return
        vm.$emit('input', clone(data))
      }
    },

    value: {
      deep: true,
      handler (value) {
        const vm = this
        const data = value ? Object.assign({}, value) : {}
        if (isEqual(vm.data, data)) return
        vm.data = clone(data)
      }
    }
  }
}

const hasConfig = (val) => !['0', 'null', 'undefined', ''].includes(String(val || '').trim())
const clone = (source) => JSON.parse(JSON.stringify(source))
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

const toStr = (val) => {
  let desc = String(val || '').trim()
  if (desc === '<p></p>') desc = ''
  return desc
}

</script>
