<template>
  <div class="fpe-root">
    <v-flex
      xs12
      class="py-0"
      ref="listComponents"
    >
      <PageComponents />
    </v-flex>

    <v-flex
      xs12
      class="px-1 py-0 areacontenteditor"
      :style="styleAreaEditor"
    >
      <PageColumns
        v-model="data"
        :is-root="true"
        ref="startPageCols"
      />
    </v-flex>
  </div>
</template>

<script>
import Vue from 'vue'
import PageBus from '@/components/page/PageBus'
import draggable from 'vuedraggable'

import PageColumns from '@/components/page/PageColumns'
import PageComponents from '@/components/page/PageComponents'

import PageAudio from '@/components/page/PageAudio'
import PageExpand from '@/components/page/PageExpand'
import PageImage from '@/components/page/PageImage'
import PageRows from '@/components/page/PageRows'
import PageText from '@/components/page/PageText'

Vue.component('draggable', draggable)

Vue.component('PageColumns', PageColumns)
Vue.component('PageComponents', PageComponents)

Vue.component('PageAudio', PageAudio)
Vue.component('PageExpand', PageExpand)
Vue.component('PageImage', PageImage)
Vue.component('PageRows', PageRows)
Vue.component('PageText', PageText)

export default {
  props: {
    value: {}
  },

  data () {
    const page = clone(this.value)
    const items = Array.isArray(page.items) ? page.items : []
    if (!items.length) items.push({ id: `p${Date.now()}`, data: { text: '<p>Escreva seu Texto Aqui ou Arraste novos elementos: Imagens, Áudios, Textos ou Colunas</p>' }, component: 'PageText' })
    const data = { items, justify: 'fpe-justify-start' }
    const styleAreaEditor = 'overflow: scroll;'
    return { data, styleAreaEditor }
  },

  mounted () {
    const vm = this
    vm.interval = setInterval(() => vm.refresh(), 350)
    vm.startPageCols = vm.$refs.startPageCols.$el
    vm.startPageCols.addEventListener('mouseover', vm.handleMouseOver, true)
    vm.startPageCols.addEventListener('mouseout', vm.handleMouseOut, true)
    vm.startPageCols.parentNode.parentNode.parentNode.parentNode.parentNode.addEventListener('click', vm.handleMouseClick, true)

    vm.$nextTick().then(() => {
      const style = 'overflow: scroll;'
      const listComponents = vm.$refs.listComponents
      const height = listComponents.getBoundingClientRect().height + 16
      vm.styleAreaEditor = style.concat(` height: ${570 - height}px;`)
    })
  },

  beforeDestroy () {
    const vm = this
    if (vm.interval) clearInterval(vm.interval)
    vm.interval = null
    vm.startPageCols.removeEventListener('mouseover', vm.handleMouseOver, true)
    vm.startPageCols.removeEventListener('mouseout', vm.handleMouseOut, true)
    vm.startPageCols.parentNode.parentNode.parentNode.parentNode.parentNode.removeEventListener('click', vm.handleMouseClick, true)
  },

  methods: {
    handleMouseOver (event) {
      const target = findOpenConfigClass(event.target)
      if (target) target.classList.add('fpe-border-config')
    },

    handleMouseOut (event) {
      const target = findOpenConfigClass(event.target)
      if (target) target.classList.remove('fpe-border-config')
    },

    handleMouseClick (event) {
      if (isHandlerOptions(event.target)) return

      document.querySelectorAll('.fpe-border-config-fixed').forEach((e) => e.classList.remove('fpe-border-config-fixed'))
      const target = findOpenConfigClass(event.target)
      if (!target) return PageBus.$emit('handler-config', {})

      target.classList.add('fpe-border-config-fixed')
      const itemId = target.dataset.itemId
      const itemType = target.dataset.itemType
      PageBus.$emit('handler-config', { itemId, itemType })
    },

    refresh () {
      const vm = this
      const page = clone(this.value)
      const items = Array.isArray(page.items) ? page.items : []
      if (isEqual(vm.data.items, items)) return

      const content = document.createElement('div')

      content.innerHTML = String(document.getElementsByClassName('areacontenteditor')[0]?.innerHTML || '')
        .replaceAll('contenteditable="true"', '')
        .replaceAll('id="collapse-toggle-', 'id="collapse-view-toggle')
        .replaceAll('for="collapse-toggle-', 'for="collapse-view-toggle')
        .trim()

      content.querySelectorAll('.fpe-handler').forEach((e) => e.remove())
      content.querySelectorAll('.fpe-open-config').forEach((e) => e.classList.remove('fpe-open-config'))
      content.querySelectorAll('.fpe-half-padding').forEach((e) => e.classList.remove('fpe-half-padding'))
      content.querySelectorAll('.fpe-border-config').forEach((e) => e.classList.remove('fpe-border-config'))
      content.querySelectorAll('.fpe-border-config-fixed').forEach((e) => e.classList.remove('fpe-border-config-fixed'))

      const pages = new Set()
      const audios = new Set()
      const images = new Set()

      content.querySelectorAll('img').forEach((img) => {
        images.add(img.src)
      })

      content.querySelectorAll('a').forEach((link) => {
        if (!link.href) return
        if (!link.href.includes('/estudos-pesquisas/visualizar/')) return
        const id = link.href.split('/estudos-pesquisas/visualizar/').pop().split('/').shift()
        pages.add(id)
      })

      content.querySelectorAll('a').forEach((link) => {
        if (!link.href) return
        if (!link.href.includes('/reproduzir/')) return
        const id = link.href.split('/reproduzir/').pop().split('?').shift()
        audios.add(id)
      })

      vm.$emit('input', {
        html: content.innerHTML,
        items: clone(vm.data.items),
        pages: Array.from(pages),
        images: Array.from(images),
        audios: Array.from(audios)
      })
    }
  },

  watch: {
    value: {
      deep: true,
      handler () {
        const vm = this
        const page = clone(this.value)
        const items = Array.isArray(page.items) ? page.items : []
        if (isEqual(vm.data.items, items)) return
        vm.data.items = items
      }
    }
  }
}

const clone = (source) => source ? JSON.parse(JSON.stringify(source)) : {}
const isEqual = (source, target) => JSON.stringify(source) === JSON.stringify(target)

const findOpenConfigClass = (target) => {
  let el = target

  for (let index = 0; index < 7; index++) {
    if (!el) return false
    if (el.classList.contains('fpe-open-config')) return el
    el = el.parentNode
  }

  return null
}

const isHandlerOptions = (target) => {
  let el = target

  for (let index = 0; index < 7; index++) {
    if (!el) return false
    if (el.classList.contains('fpe-handler')) return true
    el = el.parentNode
  }

  return false
}

</script>

<style lang="sass">
  .areacontenteditor
    border: 1px dashed grey
    background: #fff
    padding: 1em
    margin: 1em 0 0.5em 0.5em
</style>
