<template>
  <div
    class="fpe-handler"
    ref="mhandler"
    :style="styleRoot"
  >
    <div class="fpe-row">
      <div class="fpe-col-auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              dark
              x-small
              :class="handlerClass"
            >
              <v-icon color="primary">
                {{ icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>Mover</span>
        </v-tooltip>
      </div>

      <div
        class="fpe-col-auto"
        style="width: 9px"
      />

      <div class="fpe-col-auto">
        <v-tooltip
          bottom
          v-if="isHorizontal"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              dark
              x-small
              @click="$emit('add')"
              class="handler-item-add item-handler-white"
            >
              <v-icon color="green darken-2">
                mdi-land-rows-vertical
              </v-icon>
            </v-btn>
          </template>
          <span>Adicionar Coluna</span>
        </v-tooltip>
      </div>

      <div
        class="fpe-col-auto"
        style="width: 9px"
        v-if="isHorizontal"
      />

      <div class="fpe-col-auto">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              dark
              x-small
              @click="$emit('config')"
              class="handler-item-config item-handler-white"
            >
              <v-icon color="grey darken-2">
                mdi-cog-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Configurações</span>
        </v-tooltip>
      </div>

      <div
        class="fpe-col-auto"
        style="width: 9px"
      />

      <div class="fpe-col-auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              icon
              x-small
              @click="$emit('remove')"
              class="handler-item-close item-handler-white"
            >
              <v-icon color="red">
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Remover</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageHandler',

  props: {
    show: {},
    horizontal: {},
    handlerClass: {}
  },

  data () {
    const styleRoot = 'left: 0; top: 0; opacity: 0; display: none; position: absolute'
    return { styleRoot }
  },

  mounted () {
    const vm = this
    const canShow = Boolean(vm.show)
    if (canShow) vm.applyShow(true)
  },

  methods: {
    applyShow (show) {
      const vm = this
      const isHorizontal = Boolean(vm.horizontal)
      const styleRoot = calcStyle(show, isHorizontal)
      if (vm.styleRoot === styleRoot) return
      vm.styleRoot = styleRoot
    }
  },

  computed: {
    icon () {
      const vm = this
      const isHorizontal = Boolean(vm.horizontal)
      return isHorizontal ? 'mdi-arrow-collapse-horizontal' : 'mdi-arrow-collapse-vertical'
    },

    isHorizontal () {
      return Boolean(this.horizontal)
    }
  },

  watch: {
    show: {
      deep: true,
      handler (show) {
        const vm = this
        vm.applyShow(false)
        const canShow = !!show
        if (canShow) vm.$nextTick().then(() => vm.applyShow(true))
      }
    }
  }
}

const calcStyle = (show, isHorizontal) => {
  const props = { display: 'block', top: 0 }
  props.opacity = show ? 1 : 0
  props['z-index'] = show ? 999 : -999

  const xProp = isHorizontal ? 'left' : 'right'
  props[xProp] = '0'

  return Object.keys(props).reduce((grp, prop) => grp.concat(`${prop}: ${props[prop]};`), '')
}

</script>
