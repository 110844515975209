<template>
  <div class="content">
    <div class="phone android">
      <div
        ref="content"
        class="phone-con"
      >
        <div
          class="fpe-root"
          lang="pt-br"
          dir="ltr"
          role="textbox"
          v-html="strHtml"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageBus from '@/components/page/PageBus'

export default {
  props: {
    content: {}
  },

  mounted () {
    const vm = this
    PageBus.$on('handler-config', vm.scrollElement)
  },

  beforeDestroy () {
    const vm = this
    PageBus.$off('handler-config', vm.scrollElement)
  },

  computed: {
    strHtml () {
      const vm = this
      return String(vm.content || '').trim()
    }
  },

  methods: {
    scrollElement (data) {
      if (!data?.itemId) return
      const vm = this
      const container = vm.$refs.content
      const target = container.querySelector(`#${data.itemId}`)
      if (!target) return

      container.scrollTo({
        top: target.offsetTop - container.offsetTop,
        behavior: 'smooth'
      })
    }
  }
}

</script>

<style scoped lang="scss">
.content {
  height: 100%;
  overflow-y: scroll;
  overflow: inherit !important;

  .phone {
    position: unset !important;
    margin: 0;
    padding: 20px 20px 20px !important;
    text-align: unset !important;

    background: #060606ba;
    border-radius: 55px;
    box-shadow: 0px 0px 0px 2px #aaa;
    top: 8%;
    right: 5%;
    width: 320px;
    z-index: 9999;

    audio {
      max-width: 255px;
      max-height: 45px;
    }

    .phone-con {
      border-radius: 7px;
      font-size: 10px;
      word-break: break-word !important;
      padding: 15px 12px !important;
      letter-spacing: normal !important;
      line-height: normal !important;

      overflow: hidden;
      height: 560px;
      background: #fff;
      word-wrap: break-word;
      overflow-y: scroll;

      img {
        display: block;
        height: 100%;
        width: calc(320px - 40px);
        margin: 6px;
      }

      span.text-small {
        font-size: 9px !important;
      }

      span.text-tiny {
        font-size: 8px !important;
      }

      span.text-big {
        font-size: 13px !important;
      }

      span.text-huge {
        font-size: 18px !important;
      }
    }
  }

  header .phone:before {
    content: '';
    width: 60px;
    height: 10px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    background: #333;
    top: 50px;
  }

  .android {
    width: 320px !important;
    border-radius: 20px;
    height: 560px;
    margin-top: 10px;
    iframe {
      padding-top: 27px;
    }
    .phone-con {
      height: 520px;
      img {
        width: calc(320px - 30px) !important;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}
</style>
